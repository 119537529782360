import '../style/privacy.scoped.scss'
import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import Seo from '../components/seo'
import Layout from "../components/layout"
import Icon from '../components/iconfontCpt'
import { Table } from 'antd'

const upgradeRander = text => {
  if (text == 'Y') return <span className='upgrade-y'><Icon type="icon-success" size={18}/></span>
  if (text == 'N') return <span className='upgrade-n'><Icon type="icon-fill" size={16}/></span>
  return <span className='upgrade-t'>{ text }</span>
}

const AllColumnsJson = {
  nomenclature: [
    { title: <FormattedMessage id='supportPolicy.nomenclatureCol_1' />, dataIndex: 'name', key: 'name' },
    { title: <FormattedMessage id='supportPolicy.nomenclatureCol_2' />, dataIndex: 'explain', key: 'explain' }
  ],
  lts: [
    { title: <FormattedMessage id='supportPolicy.ltsCol_1' />, dataIndex: 'milestones', key: 'milestones', width: '15%' },
    { title: <FormattedMessage id='supportPolicy.ltsCol_2' />, dataIndex: 'timing', key: 'timing', width: '15%' },
    { title: <FormattedMessage id='supportPolicy.ltsCol_3' />, dataIndex: 'definition', key: 'definition' },
    { title: <FormattedMessage id='supportPolicy.ltsCol_4' />, dataIndex: 'effect', key: 'effect'}
  ],
  mogdb: [
    { title: 'Version', dataIndex: 'version', key: 'version' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'GA', dataIndex: 'ga', key: 'ga' },
    { title: 'EOM', dataIndex: 'eom', key: 'eom' },
    { title: 'EOFS', dataIndex: 'eofs', key: 'eofs' },
    { title: 'EOS', dataIndex: 'eos', key: 'eos' }
  ],
  version: [
    { title: 'MogDB Version', dataIndex: 'mogdb', key: 'mogdb' },
    { title: 'OpenGauss Version', dataIndex: 'opengauss', key: 'opengauss' }
  ],
  upgrade: [
    { 
      title: '当前版本',
      dataIndex: 'curVersion',
      key: 'curVersion'
    },
    { title: '类型', dataIndex: 'type', key: 'type' },
    {
      title: '目标版本',
      children: [
        { title: '2.0.1', dataIndex: 'ver1', key: 'ver1', render: upgradeRander },
        { title: '2.0.3', dataIndex: 'ver2', key: 'ver2', render: upgradeRander },
        { title: '2.0.4', dataIndex: 'ver3', key: 'ver3', render: upgradeRander },
        { title: '3.0.0', dataIndex: 'ver4', key: 'ver4', render: upgradeRander },
        { title: '3.0.1', dataIndex: 'ver5', key: 'ver5', render: upgradeRander },
        { title: '3.0.2', dataIndex: 'ver6', key: 'ver6', render: upgradeRander },
        { title: '3.0.3', dataIndex: 'ver7', key: 'ver7', render: upgradeRander },
        { title: '3.0.4', dataIndex: 'ver8', key: 'ver8', render: upgradeRander },
        { title: '5.0.0', dataIndex: 'ver9', key: 'ver9', render: upgradeRander },
        { title: '5.0.1', dataIndex: 'ver10', key: 'ver10', render: upgradeRander },
      ]
    }
  ]
}
const AllDataSourceJson = {
  nomenclature: [
    {key: '1', name: 'LTS', explain: <FormattedMessage id='supportPolicy.nomenclatureData_1' />},
    {key: '2', name: 'Preview', explain: <FormattedMessage id='supportPolicy.nomenclatureData_2' />},
    {key: '3', name: 'GA', explain: <FormattedMessage id='supportPolicy.nomenclatureData_3' />},
    {key: '4', name: 'EOM', explain: <FormattedMessage id='supportPolicy.nomenclatureData_4' />},
    {key: '5', name: 'EOFS', explain: <FormattedMessage id='supportPolicy.nomenclatureData_5' />},
    {key: '6', name: 'EOS', explain: <FormattedMessage id='supportPolicy.nomenclatureData_6' />},
  ],
  lts: [
    {milestones: <FormattedMessage id='supportPolicy.ltsData_1_1' />, timing: 'GA', definition: <FormattedMessage id='supportPolicy.ltsData_3_1' />, effect: <FormattedMessage id='supportPolicy.ltsData_4_1' />},
    {milestones: <FormattedMessage id='supportPolicy.ltsData_1_2' />, timing: 'EOM', definition: <FormattedMessage id='supportPolicy.ltsData_3_2' />, effect: <FormattedMessage id='supportPolicy.ltsData_4_2' values={{div: chunks => <div>{chunks}</div>}} />},
    {milestones: <FormattedMessage id='supportPolicy.ltsData_1_3' />, timing: 'EOFS', definition: <FormattedMessage id='supportPolicy.ltsData_3_3' />, effect: <FormattedMessage id='supportPolicy.ltsData_4_3' values={{div: chunks => <div>{chunks}</div>}} />},
    {milestones: <FormattedMessage id='supportPolicy.ltsData_1_4' />, timing: 'EOS', definition: <FormattedMessage id='supportPolicy.ltsData_3_4' />, effect: <FormattedMessage id='supportPolicy.ltsData_4_4' values={{div: chunks => <div>{chunks}</div>}} />}
  ],
  mogdb: [
    {version: 'V5.0', type: 'LTS', ga: '2023-07-14', eom: '2026-07-14', eofs: '2028-07-14', eos: '2029-07-14'},
    {version: 'V3.1', type: 'LTS', ga: '2022-12-30', eom: '2025-12-30', eofs: '2027-12-30', eos: '2028-12-30'},
    {version: 'V3.0', type: 'LTS', ga: '2022-06-30', eom: '2025-06-30', eofs: '2027-06-30', eos: '2028-06-30'},
    {version: 'V2.1', type: 'Preview', ga: '2021-12-30', eom: '/', eofs: '/', eos: '2022-06-30'},
    {version: 'V2.0', type: 'LTS', ga: '2021-06-30', eom: '2024-06-30', eofs: '2026-06-30', eos: '2027-06-30'}
  ],
  version: [
    { mogdb: 'V5.0.0', opengauss: 'V5.0.0' },
    { mogdb: 'V3.1.0', opengauss: 'V3.1.0' },
    { mogdb: 'V3.0.0', opengauss: 'V3.0.0' }
  ],
  upgrade: [
    { curVersion: '2.0.0', type: '升级', ver1: 'Y', ver2: 'Y', ver3: 'Y', ver4: 'N', ver5: 'N', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'N', ver10: 'N'},
    { curVersion: '2.0.1', type: '升级', ver1: '-', ver2: 'Y', ver3: 'Y', ver4: 'N', ver5: 'N', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'N', ver10: 'N'},
    { curVersion: '2.0.3', type: '升级', ver1: '-', ver2: '-', ver3: 'Y', ver4: 'N', ver5: 'N', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'N', ver10: 'N'},
    { curVersion: '2.0.4', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: 'N', ver5: 'N', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'N', ver10: 'N'},
    { curVersion: '3.0.0', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: 'Y', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'Y', ver10: 'Y'},
    { curVersion: '3.0.1', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: '-', ver6: 'Y', ver7: 'Y', ver8: 'Y', ver9: 'Y', ver10: 'Y'},
    { curVersion: '3.0.2', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: '-', ver6: '-', ver7: 'Y', ver8: 'Y', ver9: 'Y', ver10: 'Y'},
    { curVersion: '3.0.3', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: '-', ver6: '-', ver7: '-', ver8: 'Y', ver9: 'Y', ver10: 'Y'},
    { curVersion: '3.0.4', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: '-', ver6: '-', ver7: '-', ver8: '-', ver9: 'Y', ver10: 'Y'},
    { curVersion: '5.0.0', type: '升级', ver1: '-', ver2: '-', ver3: '-', ver4: '-', ver5: '-', ver6: '-', ver7: '-', ver8: '-', ver9: '-', ver10: 'Y'}
  ]
}
const titles = [
  <FormattedMessage id='supportPolicy.t1' />,
  <FormattedMessage id='supportPolicy.t2' />,
  <FormattedMessage id='supportPolicy.t3' />,
  <FormattedMessage id='supportPolicy.t4' />,
  <FormattedMessage id='supportPolicy.t5' />,
  <FormattedMessage id='supportPolicy.t6' />
]
let isClick = false

const supportPolicy = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const [activeAnchor, setActiveAnchor] = useState(0)
  const handleAnchorClick = (idx) => {
    setActiveAnchor(idx)
    const domNode = document.getElementById(idx)
    const scrollTop = domNode.offsetTop - 100
    try {
      window.scroll({
        behavior: 'smooth',
        top: scrollTop,
      })
    } catch(err) {
      if (err instanceof TypeError) {
        window.scroll(0, scrollTop)
      } else {
        throw err
      }
    }
    isClick = true
    setTimeout(() => {
      isClick = false
    }, 500)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListenerFunc)
    return () => {
      // 移除事件
      window.removeEventListener("scroll", scrollListenerFunc)
    }
  }, []);

  function scrollListenerFunc () {
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop

    let activeAnchorIdx = 0
    titles.forEach((title, idx) => {
      const domNodeTop = document.getElementById(idx).offsetTop
      if (scrollTopVal > domNodeTop) {
        activeAnchorIdx = idx + 1
        return
      }
    })
    if (!isClick) setActiveAnchor(activeAnchorIdx > titles.length ? titles.length - 1 : activeAnchorIdx)
  }


  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={false}>
      <Seo title="MogDB: supportPolicy"/>
      <div className="privacy-container support-policy">
        <div className="left-box">
          <h1><FormattedMessage id='supportPolicy.title' /></h1>
          <div className="effective-date"><FormattedMessage id='supportPolicy.time' /> 2022-08-23</div>
          <h2 id="0">1. <FormattedMessage id='supportPolicy.t1' /></h2>
          <Table dataSource={AllDataSourceJson.nomenclature} className="w-table policy-table" columns={AllColumnsJson.nomenclature} pagination={false} />
          <div className="blank-line"></div>
          <h2 id="1">2. <FormattedMessage id='supportPolicy.t2' /></h2>
          <p><FormattedMessage id='supportPolicy.p2' /></p>
          <div className="blank-line small"></div>
          <h2 id="2">3. <FormattedMessage id='supportPolicy.t3' /></h2>
          <h3><FormattedMessage id='supportPolicy.t3_1' /></h3>
          <h4><FormattedMessage id='supportPolicy.t3_1_1' /></h4>
          <img src={pageLang === 'en' ? '/LTS_en.png' : '/LTS.png'} width="100%" />
          <div className="blank-line mini"></div>
          <Table dataSource={AllDataSourceJson.lts} className="w-table policy-table" columns={AllColumnsJson.lts} pagination={false} />
          <div className="blank-line small"></div>
          <h3><FormattedMessage id='supportPolicy.t3_2' /></h3>
          <p><FormattedMessage id='supportPolicy.p3_2' /></p>
          <div className="blank-line small"></div>
          <h2 id="3">4. <FormattedMessage id='supportPolicy.t4' /></h2>
          <Table dataSource={AllDataSourceJson.mogdb} className="w-table policy-table use-bottom" columns={AllColumnsJson.mogdb} pagination={false} />

          <h2 id="4">5. <FormattedMessage id='supportPolicy.t5' /></h2>
          <Table dataSource={AllDataSourceJson.version} className="w-table policy-table use-bottom" columns={AllColumnsJson.version} pagination={false} />

          <h2 id="5">6. <FormattedMessage id='supportPolicy.t6' /></h2>
          <Table bordered dataSource={AllDataSourceJson.upgrade} className="w-table policy-table upgrade use-bottom" columns={AllColumnsJson.upgrade} pagination={false} />
          <p><FormattedMessage id='supportPolicy.p6_0' /></p>
          <p><FormattedMessage id='supportPolicy.p6_1' /></p>
          <p><FormattedMessage id='supportPolicy.p6_2' /></p>
        </div>
        <div className="right-box">
          <div className='ul-title'><Icon type="icon-navigation" size={14} /><span><FormattedMessage id='supportPolicy.guide' /></span></div>
          <ul className='ul-box'>
            { titles.map((title, idx) => <li key={idx} className={ activeAnchor === idx ? 'active' : '' } onClick={() => handleAnchorClick(idx)}>{title}</li>) }
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default supportPolicy
